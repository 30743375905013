(function($) {

  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};

  Drupal.behaviors.special_offer_row_promo = {
    attach: function(context, settings) {

      Drupal.ELB.loadPersistenUserCookie();
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var hasLoyaltyProgram = parseInt(persistentCookie.is_loyalty_member, 10);
      var $landing = $('.special-offer-row-promo');
      var $offerBtn = $('.special-offer-button-popup');
      var $rewardBtn = $('.special-reward-button');

      if (hasLoyaltyProgram) {
        $landing.addClass('is_loyalty_member');
        $offerBtn.addClass('hidden');
        $rewardBtn.removeClass('hidden');
      }
      $landing.each(function () {
        if (!$(this).find('.js-special-offer-button-popup').text()) {
          $(this).find('.special-offer-promo-text-wrapper').css('cursor', 'auto');
        }
      });

      $landing.click(function (e) {
        e.preventDefault();
        if (!$(this).find('.js-special-offer-button-popup').text()) {
          return;
        }
        if(_.isUndefined(hasLoyaltyProgram)) {
          // no loyalty program use welcome15 overlay
          $(document).trigger('welcome15.show_signup_form');
        }else {
          // use loyalty overlay
          $(document).trigger('loyalty.show_signup_form', [{joinNowButtonClicked: true}]);
        }
      });

    } // attach
  };
})(jQuery);
